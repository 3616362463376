import Uppy from "@uppy/core";
import {useEffect, useMemo} from "react";
import {Dashboard} from '@uppy/react';
import AwsS3 from "@uppy/aws-s3";
import {passThroughFunction} from "../api";
import {useStores} from "../stores";
import axios from "axios";

export function createUppy(token: string, autoProceed = true) {
    const uppy:Uppy = new Uppy({ debug: true, autoProceed, id:`uppy`, meta: { } }).use(AwsS3, {
        id: 'myAWSPlugin',
        shouldUseMultipart: (file:any) => file.size > 100 * 0x10_00_00,
        getUploadParameters:(file:any)=> {
            return passThroughFunction({
                key:'signS3File',
                data: {
                    token,
                    filename: file.name,
                    contentType: file.type,
                    entityType: file.meta.entityType,
                    entityId: file.meta.entityId,
                    companyId: file.meta.companyId,
                }
            }).then((data)=>({
                ...data.data,
                fields: [],
                method: 'PUT',
                url: data.data.url
            }))
        },
        // @ts-ignore
        createMultipartUpload:(file:any, signal:any) => {
            signal?.throwIfAborted()
            const metadata:Record<any, any> = {}
            Object.keys(file.meta || {}).forEach((key) => {
                if (file.meta[key] != null) {
                    metadata[key] = file.meta[key].toString()
                }
            })
            return axios.post('s3/multipart', {
                filename: file.name,
                contentType: file.type,
                entityType: file.meta.entityType,
                entityId: file.meta.entityId,
                metadata
            },{
                signal
            }).then((r:any)=>({
                ...r.data
            }))
        },
        // @ts-ignore
        abortMultipartUpload:(file:any, { key, uploadId }:any, signal:any) => {
            const filename = encodeURIComponent(key)
            const uploadIdEnc = encodeURIComponent(uploadId)
            return axios.delete(`s3/multipart/${uploadIdEnc}?key=${filename}`, {
                signal
            })
            .then((r:any)=>({
                ...r.data
            }))
        },
        signPart:(file:any, opts:any) => {
            // if (typeof crypto?.subtle === 'object') {
            //     // @ts-ignore
            //     return uppy?.getPlugin<any>('myAWSPlugin')?.createSignedURL(file, opts)
            // }
            const { uploadId, key, partNumber, signal } = opts
            signal?.throwIfAborted()
            if (uploadId == null || key == null || partNumber == null) {
                throw new Error('Cannot sign without a key, an uploadId, and a partNumber')
            }
            const filename = encodeURIComponent(key)
            return axios.get(`s3/multipart/${uploadId}/${partNumber}?key=${filename}`, {
                signal
            }).then((r:any) => ({
                ...r.data
            }))
        },
        // @ts-ignore
        completeMultipartUpload:(file:any, { key, uploadId, parts }:any, signal:any) => {
            console.log(parts)
            signal?.throwIfAborted()
            const filename = encodeURIComponent(key)
            const uploadIdEnc = encodeURIComponent(uploadId)
            return axios.post(`s3/multipart/${uploadIdEnc}/complete?key=${filename}`, {parts})
        }
    });
    return uppy
}

export const Dropzone = ({onComplete, onUploadSuccess, onError, companyId, entityType, entityId, autoProceed = true}:{onComplete: any, onUploadSuccess?:any, onError?:any, entityType: 'program' | 'quote' | 'comment', companyId: string, entityId: string, autoProceed?: boolean}) => {
    const { userStore } = useStores();
    const uppy = useMemo(() => {
       return createUppy(userStore.user.token, autoProceed)
    },[])

    useEffect(() => {
        if (uppy) {
            if (!onComplete) throw new Error('onComplete is required')
            uppy.on('complete', onComplete)
            uppy.setMeta({
                entityId,
                entityType,
                companyId
            })
            if (onUploadSuccess) uppy.on('upload-success', onUploadSuccess)
            if (onError) uppy.on('error', onError)
        }
    }, [uppy]);

    return <Dashboard className={'mx-auto'} uppy={uppy}/>
}