import React, { useMemo, useRef, useState } from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {useMutation, useQuery} from "react-query";
import {getQuote, passThroughFunction, signup, spryClient} from "../../api";
import { Table } from "../../Components";
import {TCompany, TQuote} from "../../types/portalTypes";
import { EQuoteStatuses } from "../../utils/constants/quotes";
import { QuoteColumns } from "./QuoteCols";
import {Dropzone} from "../../Components/dropzone";
import {useStores} from "../../stores";
import {showToast} from "../../Components/Toast/ToastManager";

const Quotes = ({
  quotes,
  programName,
  company,
  program_id,
}: {
  quotes: TQuote[];
  programName: string;
  program_id: string;
  company:TCompany;
}) => {
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const columns = QuoteColumns(setSelected);
  const { userStore } = useStores();

  const { isLoading: loadingBrands, data: brands } = useQuery("getBrands", () =>
    spryClient.getBrands({})
  );

  const { data: quote, isLoading: quoteLoading } = useQuery(["getQuote",selected], async () => {
    const res = await getQuote({ token: userStore.user.token, id: selected});
    return res;
  },{enabled:!!selected});

  const { mutate: estimateQuote, isLoading: isEstimateLoading } = useMutation(
      passThroughFunction,
      {
        onSuccess: () => {
          showToast({
            content: "Estimated",
            duration: 3000,
            error: false,
          });
        },
        onError: () => {
          showToast({
            content: "Failed to estimate",
            duration: 3000,
            error: true,
          });
        },
        onSettled: () => {

        },
      }
  );

  const data = useMemo(() => {
    if (quotes && quotes.length) {
      return quotes.map((quote) => {
        return {
          id: quote.id,
          status: quote.status ?? 0,
          creationTime: new Date(quote.created_at),
          updateTime: new Date(quote.updated_at),
          company: company.name,
          domain: company.domain,
          programBrand: quote?.program_brand ?? "N/A",
          programName: quote.program_name,
          brandId: quote?.program_brand?.id,
        };
      });
    } else {
      return [];
    }
  }, [quotes, brands,company]);
  const submitEstimate = () => {
    return estimateQuote({key: 'estimateQuote', data:{id: selected, estimate: 1000, internalId: '001-testing', token: userStore.user.token}})
  }

  return (
    <>
      <div className="dashboardContent quotes">
        {loadingBrands ? (
          <div className="spinner">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            tablePageSize={15}
            tableTitle="Quotes"
            sortbyid="updateTime"
            descending={true}
          />
        )}
        <Modal show={!!selected} onHide={() => setSelected(undefined)} centered>
          <Modal.Body>
            {!!selected ? <Dropzone companyId={company.id} autoProceed={false} entityId={selected} entityType={'quote'} onComplete={()=>alert('h')}/> : null}
            <div style={{maxHeight:300, overflow:'auto'}}>
              {JSON.stringify(quote)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={submitEstimate}>Submit Estimate</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Quotes;
