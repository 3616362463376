import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Agencies,
  Brands,
  Campaigns,
  CampaignDetail,
  Admins,
} from "./Containers";
import { useStores, StoresContext } from "./stores";
import CreateRepos from "./Containers/CreateRepos";
import { setupApiClient } from "./api";
import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import { ToastManager } from "./Components/Toast/ToastManager";
import Programs from "./Containers/Programs";
import Program from "./Containers/Programs/Program";
import Types from "./Containers/CMS/Types";
import Items from "./Containers/CMS/Items";
import ItemContainer from "./Containers/CMS/Item";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.min.css';


let refreshInterval: any;

function App() {
  const stores = useStores();
  const { userStore } = stores;

  const { user } = useAuthenticator();
  const jwtToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();

  const [token, setToken] = useState<string | undefined>();
  const [ready, setReady] = useState(false);

  if (!refreshInterval) {
    refreshInterval = setInterval(() => {
      try {
        const refreshToken = user.getSignInUserSession()?.getRefreshToken();
        if (!refreshToken) {
          return;
        }
        user.refreshSession(refreshToken, (err, session) => {
          setupApiClient(session?.idToken.jwtToken);
        });
      } catch {}
    }, 1200000);
  }

  useEffect(() => {
    if (jwtToken === token) {
      return;
    }
    setToken(jwtToken);
    if (!jwtToken) {
      return;
    }
    setupApiClient(jwtToken);
    userStore.setUserName(
      user.attributes?.email ?? "",
      jwtToken,
      (user.attributes as any)?.profile
    );
    setReady(true);
  }, [jwtToken, token, userStore, user?.attributes]);

  const isAdmin = (user?.attributes as any)?.profile === "Admin";

  const isStandard = (user?.attributes as any)?.profile === "Standard";

  return (
    <StoresContext.Provider value={stores}>
      {!ready && "Loading..."}
      {ready && (
        <>
          {!isAdmin &&
            !isStandard &&
            "User does not have 'Admin' or 'Standard' profile role"}

          {/* Admin access routes */}
          {isAdmin && (
            <Router>
              <Routes>
                <Route path="/users" element={<Admins />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/createrepo" element={<CreateRepos />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/programs" element={<Programs />} />
                <Route path="/programs/:id" element={<Program/>} />
                <Route path="/cms" element={<Types />} />
                <Route path="/cms/type/:typeId" element={<Items />} />
                <Route path="/cms/item/:itemId" element={<ItemContainer />} />


                <Route
                  path="/campaign/:campaignKey/*"
                  element={<CampaignDetail />}
                />
                <Route path="/" element={<Agencies />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Router>
          )}

          {/* Standard access routes */}
          {isStandard && (
            <Router>
              <Routes>
                <Route path="/" element={<Campaigns />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route
                  path="/campaign/:campaignKey/*"
                  element={<CampaignDetail />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Router>
          )}
          <ToastManager />
        </>
      )}
    </StoresContext.Provider>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
});
