import { Row, TableProps } from "react-table";
import { Link } from "react-router-dom";

export const ProgramColumns = () => {
  const COLUMNS = [
    
 
    {
      Header: "Program Name",
      accessor: "programName",
      Cell: (TableInfo: TableProps) => {
        const id = TableInfo.data[TableInfo.row.index].id
          
        return <Link to={`/programs/${id}`}>{TableInfo.data[TableInfo.row.index].programName}</Link>;
      },

    },
    {
      Header: "Company Name",
      accessor: "company",
      
    },
    {
      Header: "Status",
      accessor: "status",
      
    },
    {
      Header: "Created",
      accessor: "creationTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].creationTime
          ? TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "Updated",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : "";
        return dateString;
      },
    },
  ];
  return COLUMNS;
};
