import React from "react";
import { TAsset } from "../../types/portalTypes";

const Assets = ({ assets }: { assets: TAsset[] | undefined }) => {
  return (
    <div className="dashboardContent quotes">
      {assets && assets.length ? (
        <div className="quotes-head">
          {" "}
          <h3>Files</h3>
          {assets
            .filter((a) => a.type !== "url")
            .map((file) => (
              <li>
                <a href={file.downloadUrl} target="_blank" rel="noreferrer">
                  {file.name}
                </a>{" "}
                {file.size}
              </li>
            ))}
          <h3>URLs</h3>
          <ul>
            {assets
              .filter((a) => a.type === "url")
              .map((url) => (
                <li>
                  {url.name} {url.downloadUrl}
                </li>
              ))}
          </ul>
        </div>
      ) : (
        <p> Currently there are no assets uploaded.</p>
      )}
    </div>
  );
};

export default Assets;
