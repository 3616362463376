import {useStores} from "../../stores";
import {useParams} from "react-router-dom";
import {Header, NavMenu} from "../../Components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {cmsGetItem, cmsGetType, cmsListTypes, cmsUpdateItem} from "../../api";
import _ from 'lodash'
import {useForm} from "react-hook-form";
import {showToast} from "../../Components/Toast/ToastManager";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";

type FormType = {
    title: string;
    subtitle: string;
    slug: string;
    enabled: boolean;
};

export const ItemForm = ({onSubmit, className}:{onSubmit: any, className?: string}) => {
    const {userStore} = useStores();
    const {itemId} = useParams<{ itemId: string }>();
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        if (!itemId) return
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: {errors},
    } = useForm<FormType>({
        defaultValues: {
            title: "",
            subtitle: "",
            slug: "",
            enabled: false,
        },
        mode: "onTouched",
    });
    useEffect(() => {
        if (item?.slug) {
            setValue('slug', item.slug)
            setValue('title', item.title)
            setValue('subtitle', item.subtitle)
            setValue('enabled', item.enabled)
        }
    }, [setValue, item])

    const genSlug = () => {
        setValue('slug', uuidv4())
    }
    return <form className={className} onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
            <div className="row">
                <div className="col-sm-6 col-md-12">
                    <div className="form-group">
                        <div className={'flex justify-content-between'}>
                            <label htmlFor="">
                                Slug
                            </label>
                            <Link onClick={genSlug} to={'#'}>Generate</Link>
                        </div>
                        <input
                            {...register("slug", {
                                required: {
                                    value: true,
                                    message: "Please Enter a slug",
                                },
                            })}
                            placeholder="Slug (unique)"
                            className="form-control"
                        />

                        {errors.slug && (
                            <div className="error">
                                <i className="fas fa-exclamation-circle"/>
                                {errors.slug.message}
                            </div>
                        )}
                    </div>
                    <div className={'text-muted'}>Used by the API to identify different
                        items, required and unique.
                    </div>
                    <div className="italic-p">Example: blog-001</div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">
                            {item?.children?.length ? 'Title' : 'Value'}
                        </label>
                        {item?.children?.length ? <input
                            {...register("title")}
                            placeholder={"Title"}
                            className="form-control"
                        /> : <textarea
                            {...register("title")}
                            placeholder={"Value"}
                            className="form-control"
                        />}

                        {errors.title && (
                            <div className="error">
                                <i className="fas fa-exclamation-circle"/>
                                {errors.title.message}
                            </div>
                        )}
                    </div>
                    <div className={'text-muted'}>Title for the item, used by the frontend
                    </div>
                    <div className="italic-p">Example: "WILY Blog Post 2023"</div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-6 col-md-12">
                    <div className="form-group">
                        <label htmlFor="">
                            Subtitle
                        </label>
                        <input
                            {...register("subtitle")}
                            placeholder="Subtitle"
                            className="form-control"
                        />

                        {errors.subtitle && (
                            <div className="error">
                                <i className="fas fa-exclamation-circle"/>
                                {errors.subtitle.message}
                            </div>
                        )}
                    </div>
                    <div className={'text-muted'}>Subtitle for the item, used by the frontend
                    </div>
                    <div className="italic-p">Example: "Recent case-study"</div>
                </div>
            </div>

            <div className="form-group toggleBtn">
                <p>Enabled</p>
                <div onClick={() => setValue('enabled', !getValues('enabled'))}
                     className="swtichCont">
                    <label className="switch" htmlFor="checkbox1">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            {...register("enabled", {required: false})}
                        />

                        <div className="slider round"></div>
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                        <button className="btn form" type="submit">
                            {itemId ? 'Save' : 'Create'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
}

const StepContainer = ({currentStep, step, setStep, children}:{currentStep: number, step: number, setStep: any, children: any}) => {
    const canGoBack = currentStep > 1
    return <div style={{display: step === currentStep ? 'block' : 'none'}}>
        <div className={'flex justify-content-between'}>
            {canGoBack ? <Link onClick={()=>setStep(step - 1)} to={'#'}>Back</Link> : null}
        </div>
        {children}
    </div>
}

const ConnectDialog = ({open, setOpen}:{open: boolean, setOpen: any}) => {
    const [step, setStep] = useState(1)
    const [params, setParams] = useState<{type?: string}>({})
    const { userStore } = useStores();
    const queryClient = useQueryClient();
    const {itemId} = useParams<{ itemId: string }>();
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const { data: types } = useQuery("cmsListTypes", async () => {
        return cmsListTypes({token: userStore.user.token});
    });

    const { data: type } = useQuery(`cmsGetType-${params.type}`, async () => {
        return cmsGetType({token: userStore.user.token, slug: params.type || ''});
    });

    const {mutate: updateItem} = useMutation(
        cmsUpdateItem,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(`cmsGetItem-${itemId}`).then(() => {
                    // setOpen(false)
                    // setStep(1)
                });
            },
        }
    );

    const handleSetType = (slug: string) => () => {
        setParams({type: slug})
        return setStep(2)
    }

    const childrenSlugs = useMemo(() => {
        if (!item?.children?.length) return []
        return item.children.map(e => ({slug: e.slug}))
    }, [item])

    const handleDisconnectItem = (slug: string) => () => {
        if (!itemId) return
        const finalChildren = childrenSlugs.filter(e => e.slug !== slug)
        return updateItem({
            token: userStore.user.token,
            id: itemId,
            children: finalChildren
        })
    }

    const handleConnectItem = (slug: string) => () => {
        if (!itemId) return
        const finalChildren = [...childrenSlugs, {slug}]
        return updateItem({
            token: userStore.user.token,
            id: itemId,
            children: finalChildren
        })
    }

    return <Modal show={open} onHide={()=>setOpen(false)}>
        <Modal.Header>
            <Modal.Title>Connections</Modal.Title>
        </Modal.Header>
        <Modal.Body>    
            <StepContainer step={1} currentStep={step} setStep={setStep}>
                <p className={'small text-muted'}>First, select the item's type from the list below</p>
                <div style={{maxHeight: 300, overflow:'auto'}}>
                    {types?.[0]?.map(e=>{
                        return <Link onClick={handleSetType(e.slug)} to={'#'}><p>{e.title ?? e.slug}</p></Link>
                    })}
                </div>
            </StepContainer>
            <StepContainer step={2} currentStep={step} setStep={setStep}>
                <p className={'small text-muted'}>Now, select the item you would like to connect or disconnect from the list below</p>
                {type?.items?.map(e=>{
                    const isLinked = childrenSlugs.some(a=>a.slug === e.slug)
                    return <Link onClick={isLinked ? handleDisconnectItem(e.slug) : handleConnectItem(e.slug)} to={'#'} className={'flex justify-content-between'}><p>{e.title ?? e.slug}</p><span className={'text-muted small font-weight-bold'}>{isLinked ? 'Disconnect' : 'Connect'}</span></Link>
                })}
            </StepContainer>
            {/*<StepContainer step={3} currentStep={step} setStep={setStep}></StepContainer>*/}
        </Modal.Body>
    </Modal>
}

const ItemContainer = () => {
    const {userStore} = useStores();
    const queryClient = useQueryClient();
    const {itemId} = useParams<{ itemId: string }>();
    const [isLinking, setIsLinking] = useState(false)
    const {data: item} = useQuery(`cmsGetItem-${itemId}`, async () => {
        return cmsGetItem({token: userStore.user.token, id: itemId || ''});
    });
    const {mutate: updateItem} = useMutation(
        cmsUpdateItem,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(`cmsGetItem-${itemId}`);
            },
        }
    );

    const childrenSlugs = useMemo(() => {
        if (!item?.children?.length) return []
        return item.children.map(e => ({slug: e.slug}))
    }, [item])

    const submitForm = useCallback((data: FormType) => {
        if (!itemId) return
        // if (itemId === '*') return createType({token: userStore.user.token, title: data.title, enabled: data.enabled, slug: data.slug})
        return updateItem({
            token: userStore.user.token,
            id: itemId,
            enabled: data.enabled,
            slug: data.slug,
            title: data.title,
            subtitle: data.subtitle,
            children: childrenSlugs
        })
    }, [updateItem, userStore.user.token, itemId, childrenSlugs])

    const groupedChildren = useMemo(() => {
        if (!item?.children?.length) return null
        return _.groupBy(item.children, (i) => i.type.title ? i.type.title : i.type.slug)
    }, [item])

    const getChildProps = useCallback((key: string) => {
        return item?.children?.find(e => e.type.title === key) ?? null
    }, [item])

    if (!itemId) return <div/>

    const editing = (itemId !== '*' && !!item)
    const creating = (itemId === '*' && !item)
    const isValid = editing || creating
    return <>
        <div className="container-fluid p-0">
            <ConnectDialog setOpen={setIsLinking} open={isLinking}/>
            <div className="row no-gutters">
                <NavMenu/>
                <div className="col-sm-12 col-md-10">
                    <Header/>

                    <div className="dashboardContent">
                        <div className="head">
                            <Link to={`/cms/type/${item?.type.slug}`}><i className="fas fa-arrow-left"></i>Back to {item?.type?.title ?? item?.slug}</Link>
                            {isValid ? <div className={'container row row-cols-2'}>
                                <ItemForm className={'col-6'} onSubmit={submitForm} />
                                <div className={''}>
                                    <div className={'flex justify-content-between'}>
                                        <p className={'ml-1 mb-2'}><span className={'font-weight-bold'}>Connections:</span> {item?.children.length}</p>
                                        <Link onClick={() => setIsLinking(true)} to={`#`}>Manage</Link>
                                    </div>
                                    {item?.children?.length ? <div style={{maxHeight: 400, overflow:'auto'}}>
                                        {groupedChildren ? (
                                            Object.entries(groupedChildren).map(([typeKey, entries]) => {
                                                const childProps = getChildProps(typeKey)
                                                if (!childProps) return <div/>
                                                return <div className={'border p-2 rounded mb-2'}>
                                                    <Link className={'font-weight-bold'} to={`/cms/type/${childProps?.type.slug || '#'}`}>{childProps.type.title}</Link>
                                                    <div className={'mt-2'}>
                                                        {entries.map((item:any) => {
                                                            return <div className={'mb-2 flex justify-content-between border-bottom pb-2'}>
                                                                <Link style={{width: '85%'}} to={`/cms/item/${item.id}`}>{item.title}</Link>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            })
                                        ) : null}
                                        {/*{item?.children?.map((item)=>{*/}
                                        {/*    return <div className={'border flex p-2 rounded mb-2'}>*/}
                                        {/*        <Link to={`/cms/item/${item.slug}`}>{item.title ?? item.slug}</Link>*/}
                                        {/*    </div>*/}
                                        {/*})}*/}
                                    </div> : null}
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ItemContainer