import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPrograms, getQuote} from "../../api";
import { Header, NavMenu } from "../../Components";
import { useStores } from "../../stores";
import {  Spinner, Tabs, Tab } from "react-bootstrap";
import Activity from "./Activity";
import Details from "./Details";
import Assets from "./Assets";
import Tasks from "./Tasks";
import { TProgram, } from "../../types/portalTypes";
import Quotes from "./Quotes";

const Program = () => {
  const { userStore } = useStores();
  const [key, setKey] = useState("");

  const state = useParams<{ id: string }>();
  const handleKeySelection = (key: string | null) => {
    if (key) {
      setKey(key);
      window.sessionStorage.setItem("tabSelection", key);
    }
  };
 // get list of quotes from portal.
 // get list of tasks for the program 
 // get list of comments on the program
 // get list of assets
 //get details of the program

 const { data: program, isLoading: programLoading } = useQuery(
  ["getProgram", state!.id],
  async () => {
    const res:TProgram = await getPrograms ({ token: userStore.user.token,id:state.id });
    return res
  },
  { enabled: !!state.id }
);

const { data: quote, isLoading: quoteLoading } = useQuery(["getQuote",program], async () => {
  const res:any= await getQuote({ token: userStore.user.token, id:program?.quotes[0].id});
  return res;
},{enabled:!!program?.quotes[0].id});




  useEffect(() => {
    if (window.sessionStorage.getItem("tabSelection")) {
      let activeKey =
        window.sessionStorage.getItem("tabSelection") || "activity";
      setKey(activeKey);
    } else {
      setKey("activity");
    }
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <NavMenu />
        <div className="col-sm-12 col-md-10">
          <Header title={program?.quotes[0]?.program_name||"N/A"} />

          <div className="dashboardContent quotes">
              {programLoading ||quoteLoading ? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <>
                {program && state?.id ?
                
                  <div className="tabs tab10">
                    <Tabs
                      activeKey={key}
                      onSelect={(key) => handleKeySelection(key)}
                      className="nav nav-tabs"
                      style={{ width: "100%", marginBottom: "25px" }}>
                      <Tab eventKey="activity" title="Activity">
                        <Activity id={program?.id} activities ={program?.comments||[]}/>
                      </Tab>
                      <Tab eventKey="details" title="Details">
                        <Details details={program} quote={quote} />
                      </Tab>
                      <Tab eventKey="tasks" title="Tasks">
                        <Tasks tasks={program.tasks} programName={program?.quotes[0]?.program_name||"N/A"} program_id={state.id}/>
                      </Tab>
                      <Tab eventKey="quotes" title="Quotes">
                        <Quotes quotes={program.quotes} programName={program?.quotes[0]?.program_name||"N/A"} program_id={state.id} company={program.company}/>
                      </Tab>
                      <Tab eventKey="assets" title="Assets">
                        <Assets assets ={program.assets}/>
                      </Tab>
                    </Tabs>
                  </div>
                  : <p> No quote details to display </p>}
                </>
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default Program;
