import dayjs from "dayjs";
import React, { useCallback, useState,useEffect } from "react";
import {useMutation, useQueryClient} from "react-query";
import {passThroughFunction} from "../../api";
import {useForm} from "react-hook-form";
import {useStores} from "../../stores";

const Activity = ({ activities, id }: { activities: any[], id?:string }) => {
  const { userStore } = useStores();
  const [message,setMessage]=useState("")
  const queryClient = useQueryClient();
  const [users, setUsers] = useState<{ user: string; color: string; background: string }[]>();
  // TODO: this is expensive, do we need this?
  // Emails can contain "wily", perhaps we should check for our full domain(s)
  const assigncolors = useCallback( () => {
    var colors = ["#231b1b", "#ffff", "#ffff"];
    var backgrounds = ["#84FFA0", "#ef2d61", "#5240bc"];
    const users = activities.map((a) => a.creator?.email || 'wily')
    // const users = ["Anusha Chokka","pratyusha milinkoval","wily"]
    const userColors = users?.map((u) => {
      if (u.includes("wily")) {
        return { user: u, background: "black", color: "white" };
      } else {
        var random = Math.floor(Math.random() * colors.length);
        return { user: u, background: backgrounds[random], color: colors[random] };
      }
    });
    setUsers(userColors);
  },[activities])
  const { mutate: adminCreateComment, isLoading: isCreatingComment } = useMutation(
      passThroughFunction,
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["getProgram", id])
        },
        onError: () => {
        },
        onSettled: () => {
          queryClient.invalidateQueries("getUserList");
        },
      }
  );
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<{content: string}>({
    defaultValues: {
      content: "",
    },
    mode: "onTouched",
  });
  useEffect(() => {
    assigncolors()
  }, [assigncolors])

  const handleCreateComment = (data: {content: string}) => {
    return adminCreateComment({key: 'adminCreateComment', data: {token: userStore.user.token, content: data.content, entityType: 'program', entityId: id}})
  }

  useEffect(() => {
    if (activities.length) activities.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
  }, [activities]);

  return (
    <>
      {activities && activities.length && users ? (
        <div className={'activity-container'}>
          {activities.map((a,index)=>
            <div className="activity flex" key={index}>
              <div
                className={"shortName"}
                style={{
                  color: users?.find((u) => u.user === a.creator?.email)?.color || 'white',
                  background: users?.find((u) => u.user === a.creator?.email)?.background || 'red',
                }}>
                {a.creator?.email?.charAt(0).toUpperCase() || 'WILY'}
                  {(a.creator?.email?.split(".")[1]??" ")?.charAt(0)?.toUpperCase()}
              </div>
              <div className="panel panel-default">
                {/* <!-- Default panel contents --> */}
                <div className="panel-heading">
                  <span>{a.creator?.email || 'WILY'}</span>
                  {dayjs(a.created_at).format("MMM DD ")}
                 ({dayjs(a.created_at).format('MM DD ')})
                </div>
                <div className="panel-body">
                  <p>{a.content}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>Currently there are no activities to display.</p>
      )}
       <form onSubmit={handleSubmit(handleCreateComment)} className="footer-message container">
          <div className="row gap-2">
            <textarea
              {...register('content')}
              className="form-control col"
              value={message}
              onChange={(e)=>{setMessage(e.currentTarget.value)}}
            />
            <button className={'btn col col-1'} type={'submit'}>Post</button>
          </div>
        </form>
    </>
  );
};

export default Activity;
