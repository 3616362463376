import {useStores} from "../../stores";
import {useQuery} from "react-query";
import {cmsListTypes} from "../../api";
import {Header, NavMenu, Table} from "../../Components";
import {Spinner} from "react-bootstrap";
import React from "react";
import {TypesColumns} from "./TypesColumns";
import {Link} from "react-router-dom";

const Types = () => {
    const { userStore } = useStores();
    const { data: types, isLoading } = useQuery("cmsListTypes", async () => {
        return cmsListTypes({token: userStore.user.token});
    });
    return <>
        <div className="container-fluid p-0">
            <div className="row no-gutters">
                <NavMenu />
                <div className="col-sm-12 col-md-10">
                    <Header />

                    <div className="dashboardContent">
                        <div className="head">
                            <div className={'flex justify-content-between'}>
                                <p className={'ml-1 text-muted'}>Select a content type to edit it, or view its items</p>
                                <Link to={'/cms/type/*'}>
                                    <button className={'btn'}>Create</button>
                                </Link>
                            </div>
                            {isLoading ? (
                                <div className="spinner">
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                            ) : (
                                <Table
                                    columns={TypesColumns()}
                                    data={types ? types[0] : []}
                                    tablePageSize={15}
                                    tableTitle="Content Types"
                                    sortbyid="enabled"
                                    descending={true}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Types