import { SPRYClient, setSPRYClientAxios } from "@sprycore/spry-api-client";
import axios from "axios";
import { IUser, ICampaignUser } from "../interfaces";
import {ICMSItem} from "../types/portalTypes";

export const endPoint =   process.env.REACT_APP_API_ENDPOINT||"http://localhost:31337" 
// export const endPoint =  "http://localhost:31337"
export const spryClient = new SPRYClient(endPoint!);

setSPRYClientAxios(axios);
spryClient.setFollowContinuationTokens(true);

export const setupApiClient = (token: string) => {
  spryClient.setAuthorization(token);
};

export const getPrograms = async ({ token, id }: { token: string; id?: string }) => {
  const res: { programs:any } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/listPrograms`,
    data: { programId:id },
  }).then((res) => res.data);
  return res.programs;
};

export const  getQuotes = async ({ token, id }: { token: string; id?: string }) => {
  const res: { quotes: any[] } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/admin/quote/s`,
    data: { id },
  }).then((res) => res.data);
  return res.quotes;
};
export const getQuote = async ({ token, id }: { token: string; id?: string }) => {
  const res: { quotes: any } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/getQuote`,
    data: {id},
  }).then((res) => res.data);
  return res;
};
export const getTasks = async (data: { token: string; programId: string }) => {
  const { programId, token } = data;
  const res: { tasks: any []} = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/getTasks`,
    data: { programId },
  }).then((res) => res.data);
  return res;
};
export const createTask = async (data: {
  token: string;
  name: string;
  description: String;
  dueDate: string;
  status: string;
  programId: string;
}) => {
  const { description, dueDate, name, programId, status,token } = data;
  const res: { quotes: any } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/createTask`,
    data: { name, description, dueDate, status, programId },
  }).then((res) => res.data);
  return res;
};
export const saveTask = async (data: {
  token: string;
  name: string;
  description: String;
  dueDate: string;
  status: string;
  programId: string;
  id: string;
}) => {
  const { description, dueDate, name, programId, status, id, token } = data;
  const res: { quotes: any } = await axios({
    method: "post",
    headers: { authorization:token! },
    url: `${endPoint}/saveTask`,
    data: { name, description, dueDate, status, id, programId},
  }).then((res) => res.data);
  return res;
};
export const signup = async (data: IUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/signup`,
    data,
  });
  return res;
};
export const editUser = async (data: IUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/editUser`,
    data,
  });
  return res;
};

export const campaignEditUser = async (data: ICampaignUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignEditUser`,
    data,
  });
  return res;
};

export const campaignSignup = async (data: ICampaignUser) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignSignup`,
    data,
  });
  return res;
};

export const campaignResendPassword = async (data: {
  userName: string;
  poolId: string;
  token: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignResendPassword`,
    data,
  });
  return res;
};

export const resendPassword = async (data: { userName: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/resendPassword`,
    data,
  });
  return res;
};

export const getUserList = async (token: string) => {
  const res = await axios({
    method: "post",
    headers: { authorization: token },
    url: `${endPoint}/getUserList`,
    data: {},
  });
  return res;
};

export const campaignGetUserList = async (data: { poolId: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignGetUserList`,
    data,
  });
  return res;
};

export const deleteUser = async (data: { userName: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/deleteUser`,
    data,
  });
  return res;
};

export const campaignDeleteUser = async (data: {
  userName: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignDeleteUser`,
    data,
  });
  return res;
};

export const addUsertoGroup = async (data: { userName: string; role: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/addUsertoGroup`,
    data,
  });
  return res;
};

export const campaignAddUsertoGroup = async (data: {
  userName: string;
  role: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignAddUsertoGroup`,
    data,
  });
  return res;
};

export const removeFromGroup = async (data: { userName: string; role: string; token: string }) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/removeFromGroup`,
    data,
  });
  return res;
};

export const campaignRemoveFromGroup = async (data: {
  userName: string;
  role: string;
  token: string;
  poolId: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/campaignRemoveFromGroup`,
    data,
  });
  return res;
};

export const createRepos = async (data: {
  repoName: string;
  createApi?: boolean;
  createAdmin?: boolean;
  token: string;
  projectName: string;
}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/createRepo`,
    data: { repoName: data.repoName, projectName: data.projectName },
  });

  return res;
};

export const getCognitoPools = async (token: string) => {
  const res = await axios({
    method: "post",
    headers: {
      authorization: token,
    },
    url: `${endPoint}/getCognitoPools`,
  });
  return res;
};
export const cmsListTypes = async ({ token }: { token: string; }) => {
  const res: [{ slug: string, title?:string, enabled: boolean }[], number] = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/cmsListTypes`,
    data: { tk: token },
  }).then((res) => res.data);
  return res;
};

export const cmsGetType = async ({ token, slug }: { token: string; slug: string }) => {
  const res: { items: ICMSItem[], title: string, slug: string, enabled: boolean } = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/cmsGetType`,
    data: { tk: token, slug },
  }).then((res) => res.data);
  return res;
};

export const cmsGetItem = async ({ token, id }: { token: string; id: string }) => {
  const res: ICMSItem = await axios({
    method: "post",
    headers: { authorization: token! },
    url: `${endPoint}/cmsGetItem`,
    data: { tk: token, id },
  }).then((res) => res.data);
  return res;
};

export const cmsUpdateType = async (data: {token: string, oldSlug: string, enabled?: boolean, title?: string, slug?: string}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/cmsUpdateType`,
    data,
  });
  return res;
};

export const cmsUpdateItem = async (data: {token: string, id: string, subtitle?:string, enabled?: boolean, title?: string, slug?: string, children?:any[]}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/cmsUpdateItem`,
    data,
  });
  return res;
};

export const cmsCreateType = async (data: {token: string, slug: string, enabled: boolean, title?: string}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/cmsCreateType`,
    data,
  });
  return res;
};

export const cmsCreateItem = async (data: {token: string, slug: string, enabled: boolean, title?: string, subtitle?: string, type: {slug: string}}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/cmsCreateItem`,
    data,
  });
  return res;
};
export const passThroughFunction = async ({key, data}:{key: string, data: Record<any, any> & {token: string}}) => {
  const res = await axios({
    method: "post",
    headers: { authorization: data.token! },
    url: `${endPoint}/${key}`,
    data,
  });
  return res;
}