import { useState, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { NavMenu, Header } from "../../Components";
import AddCampaign from "./AddCampaign";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { CampaignColumns } from "./campaignColumns";
import { Table } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import { toJS } from "mobx";
import { spryClient } from "../../api";
import { useBodyClass } from "../../utils/hooks";
import { useStores } from "../../stores";
import Popup from "../../Components/Popup";
import {
  Campaign,
  CampaignLanguageSetting,
} from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { useNavigate } from "react-router-dom";

const Campaigns = () => {
  useBodyClass("adminPg");
  const navigate = useNavigate()

  const [show, setShow] = useState(false);

  const queryClient = useQueryClient();

  const { userStore } = useStores();

  let userGroup = toJS(userStore.user.role);

  const { campaignStore, filtersStore } = useStores();
  const [loading, setLoading] = useState(false);

  const [filterValue, setFilterValue] = useState({
    agency: filtersStore.filters.agency,
    brand: filtersStore.filters.brand
  });

  useMutation(spryClient.deleteCampaign, {
    onSuccess: (data) => {
      showToast({
        content: "Deleted Campaign",
        duration: 3000,
        error: false,
      });
    },
    onError: () => {
      setShowModal(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries("getCampaigns");
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const { isLoading: loadingAgencies, data: agencies } = useQuery(
    "getAgencies",
    async () => await spryClient.getAgencies({})
  );
  const { isLoading: loadingBrands, data: brands } = useQuery(
    "getBrands",
    async () => await spryClient.getBrands({})
  );

  const columns = useMemo(
    () => CampaignColumns({
      setShow,
      campaignStore,
      setSelectedCampaign,
      setShowModal,
      navigate
    }),
    [setShow, campaignStore, setSelectedCampaign, setShowModal, navigate]);

  const calldelete = async () => {
    setShowModal(false);
    if (loading) {
      return;
    }
    setLoading(true);

    const languages =
      selectedCampaign &&
      (await spryClient.getCampaignLanguageSettings({
        campaignKey: selectedCampaign?.campaignKey,
      }));
    if (languages && languages.campaignLanguageSettings.length > 0) {
      const deleteLanguagesettings = Promise.all(
        languages.campaignLanguageSettings.map(async function (
          language: CampaignLanguageSetting
        ) {
          await spryClient.deleteCampaignLanguageSettings({
            campaignKey: selectedCampaign.campaignKey,
            language: language.language,
          });
        })
      );

      await deleteLanguagesettings.catch((e) => {
        setLoading(false);
        showToast({
          content: "Error while deleting Language settings",
          duration: 3000,
          error: true,
        });
      });
    }
    selectedCampaign &&
      (await spryClient
        .deleteCampaign({ campaignKey: selectedCampaign?.campaignKey })
        .then((res) => {
          showToast({
            content: `${selectedCampaign.campaignName} has been deleted successfully!`,
            duration: 3000,
            error: false,
          });
          refetchCampaigns();
          setLoading(false);
        })
        .catch((e) => {
          showToast({
            content: `${e.toString()}`,
            duration: 3000,
            error: true,
          });
          setLoading(false);
        }));
    // setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const { data: campaignsData, refetch: refetchCampaigns } = useQuery("getCampaigns", () => spryClient.getCampaigns({}));
  const { data: brandData } = useQuery("getAllBrands", () => spryClient.getBrands({}));
  const { data: agenciesData } = useQuery("getAgencies", () => spryClient.getAgencies({}));

  const allCampaigns = useMemo(() => {
    if (campaignsData?.campaigns && agenciesData?.agencies && brandData?.brands) {
      let _campaigns;

      _campaigns = campaignsData.campaigns.map((campaign: any) => {
        return {
          ...campaign,
          brand: brandData.brands.filter(
            (x) => x.brandKey === campaign.brandKey
          )[0],
          agency: agenciesData.agencies.filter(
            (x) => x.agencyKey === campaign.agencyKey
          )[0],
        };
      });

      if (filterValue.agency || filterValue.brand) {
        if (filterValue.agency) {
          _campaigns = _campaigns.filter(
            (x) => x.agencyKey === filterValue.agency
          );
        }

        if (filterValue.brand) {

          _campaigns = _campaigns.filter(
            (x) => x.brandKey === filterValue.brand
          );
        }
      }

      return _campaigns && _campaigns.length > 0 ? _campaigns : [];
    }
  }, [campaignsData, agenciesData, brandData, filterValue]);

  const handleFilter = (type: string, value: string) => {
    if (type === "agency") {
      setFilterValue({
        ...filterValue,
        agency: value,
      });
      filtersStore.setagencyFilter(value);
    } else {
      setFilterValue({
        ...filterValue,
        brand: value,
      });
      filtersStore.setbrandfilter(value);
    }
  };

  return (
    <>
      {showModal && (
        <div className="row no-gutters">
          <Popup
            action1={calldelete}
            closeModal={handleClose}
            header="Delete?"
            isOpen={showModal}
            yesMessage="Yes, Delete"
            cancelMessage="Cancel"
            action2={handleClose}
            message="Are you sure, you want to delete the campaign?"
          />
        </div>
      )}
      {loading || loadingAgencies || loadingBrands || !agenciesData?.agencies || !brandData?.brands ? (
        <div className="spinner">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <NavMenu />

            <div className="col-sm-12 col-md-10">
              <Header />
              <div className="dashboardContent campaigns">
                <div className="head">
                  <button
                    className="btn addbtn"
                    disabled={userGroup === "Admin" ? false : true}
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    Add campaign
                  </button>

                  <div className="tableFilter">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <select
                          className="form-control"
                          value={filterValue.agency}
                          onChange={(e) => {
                            handleFilter("agency", e.target.value);
                          }}
                        >
                          <option value="">All agencies</option>
                          {agenciesData &&
                            agenciesData.agencies
                              .sort(function (a, b) {
                                return a.agencyName.localeCompare(b.agencyName);
                              })
                              .map((agency: any) => {
                                return (
                                  <option
                                    value={agency.agencyKey}
                                    key={agency.agencyKey}
                                  >
                                    {agency.agencyName}
                                  </option>
                                );
                              })}
                        </select>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <select
                          className="form-control"
                          name="brandKey"
                          value={filterValue.brand}
                          onChange={(e) => {
                            handleFilter("brand", e.target.value);
                          }}
                        >
                          <option value="">All brands</option>
                          {brandData &&
                            brandData.brands
                              .sort(function (a, b) {
                                return a.brandName.localeCompare(b.brandName);
                              })
                              .map((brand: any) => {
                                return (
                                  <option
                                    value={brand.brandKey}
                                    key={brand.brandKey}
                                  >
                                    {brand.brandName}
                                  </option>
                                );
                              })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <AddCampaign showSidebar={show} closeHandler={setShow} agencies={agencies ? agencies.agencies : []} brands={brands ? brands.brands : []} />

                {!allCampaigns ? (
                  <div className="spinner">
                    <Spinner animation="border" variant="secondary" />
                  </div>
                ) : (
                  allCampaigns && (
                    <Table
                      columns={columns}
                      data={allCampaigns}
                      tablePageSize={15}
                      tableTitle="Campaigns"
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Campaigns;
