export const EQuoteStatuses:{[key:string]:string} = {
    '0':'Draft',
    '1':'Pending Estimate' ,
    '2':'Decision Ready',
    '3':'Approved',
    '4':'Rejected',
    '5':'Changes Requested',
    '6':'On Hold',
    '7':'Archived',
}

export const EProgramStatus:{[key:string]:string} = {
    '0':'Quoting',
    '1':'Approved' ,
    '2':'ProgramDetails',
    '3':'Development',
    '4':'Live',
    '5':'Closed',
}

