import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import DatePickerCalendar from "../CampaignDetail/DatePickerCalendar";
import icoClock from "../../assets/images/ico-clock.png";
import { createTask, saveTask } from "../../api";
import { useStores } from "../../stores";
import { TTask } from "../../types/portalTypes";
import dayjs from "dayjs";
import idelete from "../../assets/images/ico-trash.png";
import { showToast } from "../../Components/Toast/ToastManager";

type TaskForm = {
  name: string;
  description: string;
  dueDate: string;
  status: string;
  id: string;
  programId:string;
};

const Tasks = ({tasks, programName,program_id }: { tasks: TTask[],programName:string,program_id:string }) => {
  const { userStore } = useStores();

  const [isOpen, setIsopen] = useState(false);

  const [loading, setLoading] = useState(false);
  const  tempTasks: TaskForm[] = [
    {
      name: "task 1",
      description: "description 1",
      dueDate: dayjs().startOf('day').format("MMM DD,YYYY HH:mm:ss"),
      status: "0",
      id: "dkfdj-eirowuoi-eriowerio",
      programId:program_id
    },
    {
      name: "task 2",
      description: "description 2",
      dueDate: dayjs().startOf('day').format("MMM DD,YYYY HH:mm:ss"),
      status: "0",
      id: "rewere-eirowuoi-eriowerio",
      programId:program_id

    },
    {
      name: "task 3",
      description: "description 3",
      dueDate: dayjs().startOf('day').format("MMM DD,YYYY HH:mm:ss"),
      status: "1",
      id: "kj3234-fifjiuo-mwermen",
      programId:program_id
    },
  ];


  const saveTasksForm = useForm<{ tasks: TaskForm[] }>({
    mode: "onChange",
    defaultValues: { tasks: tasks?[...tasks]:[...tempTasks] },
  });
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    
  } = useForm<TaskForm>({
    mode: "onChange",
    defaultValues: { dueDate: new Date().toISOString(), description: "", name: "", status: "0" },
  });

  console.log(errors)


  const saveWatch = saveTasksForm.watch();
  const formWatch = watch();
  const handleAdd = async (data: TaskForm) => {
    try {
      await createTask({ ...data, token: userStore.user.token, programId: program_id });
      showToast({
        content: `You have successfully added a task for ${programName}`,
        duration: 3000,
        error: false,
      });
    } catch (e) {}
  };
  const saveTasks = async (data: { tasks: TaskForm[] }) => {
    console.log(data)
    if (loading || !data.tasks.length) {
      return;
    }
    setLoading(true);
    try{
      await Promise.all(data.tasks.map(async (t) => await saveTask({...t,token: userStore.user.token})))
      showToast({
        content: "Tasks saved successfully.",
        duration: 3000,
        error: false,
      });

    }
    catch (e) {
      showToast({
        content: "Error while saving Tasks.",
        duration: 3000,
        error: true,
      });

    }
    setLoading(false)
  };
  console.log(saveWatch);
  return (
    <>
      <div>
        <div className="taskHead">
          <button className="btn  action-buttons-sm" onClick={() => setIsopen(true)}>
            Add Task
          </button>
          <button className="btn  action-buttons-sm" type="submit" form="saveTasksForm">Save</button>
        </div>
        <div>
          <div id="tab05" className="tab-contents">
            <form onSubmit={saveTasksForm.handleSubmit(saveTasks)} id="saveTasksForm">
              {saveWatch.tasks.map((t, index) => (
                <div
                  key={index}
                  className="col-sm-12 col-md-8"
                  style={{
                    border: "1px solid #CBD4DB",
                    marginBottom: "2rem",
                    borderRadius: "5px",
                    padding: "2rem",
                  }}>
                  <div className="flex" style={{ justifyContent: "space-between" }}>
                    <h5>
                      <strong>Task {index + 1} </strong>
                    </h5>
                    <div>
                      <button
                        className="linkButton"
                        type="button"
                        disabled={index === 0 || !saveWatch.tasks.length ? true : false}
                        onClick={() => {
                          console.log("clicked");
                          let temp = saveWatch.tasks[index];
                          let updatedvalues = [...saveWatch.tasks];
                          updatedvalues[index] = saveWatch.tasks[index - 1];
                          updatedvalues[index - 1] = temp;
                          saveTasksForm.reset({ tasks: [...updatedvalues] });
                        }}>
                        <i className="fas fa-arrow-up"></i>

                        {/* <img src="/assets/images/svg/arrow-up.svg" alt="arrow_up" /> */}
                      </button>
                      <button
                        className="linkButton"
                        type="button"
                        // disabled={
                        //   index === tasks.length - 1 || !saveWatch.tasks.length ? true : false
                        // }
                        onClick={() => {
                          let temp = saveWatch.tasks[index];
                          let updatedvalues = [...saveWatch.tasks];
                          updatedvalues[index] = saveWatch.tasks[index + 1];
                          updatedvalues[index + 1] = temp;
                          saveTasksForm.reset({ tasks: [...updatedvalues] });
                        }}>
                        <i className="fas fa-arrow-down"></i>

                        {/* <img src="/assets/images/svg/arrow-up.svg" alt="arrow_up" /> */}
                      </button>
                      <button
                        type="button"
                        className="delete-Button linkButton"
                        onClick={() => {
                          let temp = saveWatch.tasks[index];
                          saveTasksForm.reset({
                            tasks: saveWatch.tasks.filter((t) => t.id !== temp.id),
                          });
                        }}>
                        <span>
                          <img src={idelete} alt="delete" className="trash-img"></img>
                        </span>{" "}
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Task name</label>
                        <input
                          className="form-control"
                          {...saveTasksForm.register(`tasks.${index}.name`, { required: false })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Task description</label>
                        <textarea className="form-control" value={t.description} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="clock start">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Due date</label>
                          <DatePickerCalendar
                            value={new Date(saveWatch.tasks[index].dueDate||new Date().toString())}
                            onChange={(val: Date) => saveTasksForm.setValue(`tasks.${index}.dueDate`, val.toISOString())}
                            readOnly={false}
                            // dateforTime={new Date(saveWatch.tasks[index].dueDate)}
                            // timeInterval={30}
                          />
                          <span className="timeIco">
                            <img src={icoClock} alt="Select Time" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Status</label>
                        <select
                          className="form-control"
                          {...register("status", {
                            required: { value: true, message: "Please select a status" },
                          })}>
                          <option value="0"> To Do</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={() => {}} centered>
        <form onSubmit={handleSubmit(handleAdd)}>
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setIsopen(false);
              }}>
              <span aria-hidden="true">&times;</span>
            </button>

            <h3>Add a task</h3>
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Task name</label>
              <input
                className="form-control"
                {...register("name", {
                  required: { value: true, message: "Please enter a task name." },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Task description</label>
              <textarea
                className="form-control"
                {...register("description", {
                  required: { value: true, message: "Please enter a task description." },
                })}
              />
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="clock start">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Due date</label>
                    <DatePickerCalendar
                      value={new Date(formWatch.dueDate)}
                      onChange={(val: Date) => setValue("dueDate", val.toISOString())}
                      readOnly={false}
                      dateforTime={new Date(formWatch.dueDate)}
                      timeInterval={30}
                    />
                    <span className="timeIco">
                      <img src={icoClock} alt="Select Time" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Status</label>
                  <select
                    className="form-control"
                    {...register("status", {
                      required: { value: true, message: "Please select a status" },
                    })}>
                    <option value="0"> To Do</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary small"
              onClick={async () => {
                setIsopen(false);
              }}>
              Cancel
            </button>
            <button type="submit" className="btn btn-secondary small outline">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Tasks;
