import { createContext, useContext } from "react"
import UserStore from "./UserStore"
import { CampaignStore } from "./CampaignStore"
import FiltersStore from './FiltersStore'

class RootStore {
    public userStore = new UserStore()
    public campaignStore = new CampaignStore()
    public filtersStore = new FiltersStore()
}

export { CampaignStore, UserStore, FiltersStore }

export const StoresContext = createContext(new RootStore());

export const useStores = () => useContext(StoresContext);