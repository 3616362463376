import {Row, TableProps} from "react-table";
import { Link } from "react-router-dom";
import {Button} from "react-bootstrap";
import {TQuote} from "../../types/portalTypes";
import {EQuoteStatuses} from "../../utils/constants/quotes";

export const QuoteColumns = (onSelected: any) => {
  const COLUMNS = [
    {
      Header: "No",
      width: "5%",
      accessor: "no",
      Cell: (row: any) => {
        return <div>{Number(row.row.id) + 1}</div>;
      },
    },
    {
      Header: "Program Name",
      accessor: "programName",
      Cell: (TableInfo: TableProps) => {
        return <Link to={""}>{TableInfo.data[TableInfo.row.index].programName}</Link>;
      },
    },
    {
      Header: "Company",
      accessor: "company",
      Cell: (TableInfo: TableProps) => {
        return <div>{(TableInfo.row.original as any).company} <span>{(TableInfo.row.original as any).domain}</span></div>
      }
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (TableInfo: TableProps) => {
        return <div>{EQuoteStatuses[(TableInfo.row.original as TQuote).status]}</div>
      }
    },
    {
        Header: "Created",
        accessor: "creationTime",
        sortType: (rowA: Row, rowB: Row, columnId: number) => {
          if (rowA.values[columnId] > rowB.values[columnId]) return 1;
          if (rowB.values[columnId] > rowA.values[columnId]) return -1;
          return 0;
        },
        Cell: (TableInfo: TableProps) => {
          const dateString = TableInfo.data[TableInfo.row.index].creationTime
            ? TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
            : "";
          return dateString;
        },
      },
      {
        Header: "Updated",
        accessor: "updateTime",
        sortType: (rowA: Row, rowB: Row, columnId: number) => {
          if (rowA.values[columnId] > rowB.values[columnId]) return 1;
          if (rowB.values[columnId] > rowA.values[columnId]) return -1;
          return 0;
        },
        Cell: (TableInfo: TableProps) => {
          const dateString = TableInfo.data[TableInfo.row.index].updateTime
            ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
            : "";
          return dateString;
        },
      },
    {
      Header: "Action",
      accessor: "",
      Cell: (TableInfo: TableProps) => {
        return <Button disabled={(TableInfo.row.original as TQuote).status !== '1'} onClick={() => onSelected((TableInfo.row.original as {id:string}).id)}>Review</Button>;
      },
    }
  ];
  return COLUMNS;
};
