import { DynamicFieldData } from "../../types/portalTypes";

export const overviewFormFields: {[key:string]: DynamicFieldData} = {
    language: {
        label: "What language(s) will this program be in?",
        inputType: "radios",
        name: "language",  
        options: [{label: "English only", value: "en"},{label: "French only", value: "fr"},{label: "English and French", value: "en&fr"},{label: "Other", value: "other"},],
        status: "QUOTE"  
    },
    start_date: {
        label: "Project start date",
        inputType: "date",
        name: "start_date",
        status: "QUOTE"  
    },
   
    end_date: {
        label: "Project end date",
        inputType: "date",
        name: "end_date",
        status: "QUOTE"  
    },
    
    device: {
        label: "How will users participate with this program?",
        inputType: "radios",
        name: "device",  
        options: [{label: "Using their own device (smartphone/tablet/PC)", value: "own"},{label: "Using a third-party device (smartphone/tablet/PC)", value: "third"},],
        status: "QUOTE"  
    },
    access: {
        label: "How will users access/unlock your program? (i.e. what gateway mechanic will be used to unlock the digital experience?)",
        inputType: "radios",
        name: "access",  
        options: [
          {label: "Vanity URL", value: "url"},
          {label: "QR code", value: "qr"},
          {label: "SMS", value: "sms"},
          {label: "NFC/RFID", value: "nfc"},
          {label: "Lives within an existing website/app", value: "existing site/app"},
          {label: "Other", value: "other"},
        ],
        status: "QUOTE"  
    },
    promoted: {
        label: "How will this program be promoted?",
        inputType: "radios",
        name: "promoted",  
        options: [
          {label: "On-packaging", value: "On-packaging"},
          {label: "In-store Point of sales/merchandising", value: "point of sales"},
          {label: "Onsite signage", value: "Onsite signage"},
          {label: "Promotional Staff/Brand Ambassadors", value: " Promotional Staff/Brand Ambassadors"},
          {label: "Email blast", value: "Email blast"},
          {label: "Social media", value: "Social media"},
          {label: "Web banners", value: "Web banners"},
          {label: "Website", value: "Website"},
          {label: "TV media", value: "TV media"},
          {label: "Other", value: "Other"},
        ],
        status: "QUOTE"  
    },
     
      deploy_region: {
          label: "What market(s) are you deploying in? (countries/cities/regions)",
          inputType: "text",
          name: "deploy_region",  
          status: "QUOTE"  
      },
      deploy_locations: {
          label: "What locations are you deploying at? (retailers/events/venues if any)",
          inputType: "textarea",
          name: "deploy_locations",  
          status: "QUOTE"  
      },
      numberoflocation: {
          label: "How many locations are you deploying at?",
          inputType: "text",
          name: "numberoflocation", 
          status: "QUOTE"   
      },
      anticipate_number: {
        label: "How many consumers do you anticipate will participate?",
        inputType: "text",
        name: "anticipate_number", 
        status: "QUOTE"         
    },
      days_in_market: {
        label: "How many days are you in market for?",
        inputType: "text",
        name: "days_in_market",   
        status: "QUOTE"    
    },
  
    program_date_type: {
        label: "Are these consecutive days or event days?",
        inputType: "radios",
        name: "program_date_type",  
        options: [
          {label: "Consecutive", value: "Consecutive"},
          {label: "Event days", value: "Event days"},        
        ],
        status: "QUOTE"  
    },
      pay_require: {
        label: "Is the microsite being activated within an event where paid admission is required?",
        inputType: "radios",
        name: "pay_require",  
        options: [
          {label: "Yes", value: "yes"},
          {label: "No", value: "no"}, 
        ],
        status: "PROGRAM"  
    },
      internet_access: {
        label: "Do you have reliable internet access or your event activation days?",
        inputType: "radios",
        name: "internet_access",  
        options: [
          {label: "Yes", value: "yes"},
          {label: "No, BUT: we will work in OFFLINE mode for data capture campaigns ONLY", value: "no"},        
          {label: "No, BUT: an APP is needed to award Instant Wins prizes during the event", value: "offline_instant_win"},        
          {label: "N/A", value: "N/A"},        
        ],
        status: "PROGRAM"  
    },
      
      
      privacy_url:{
        label: "Company/brand privacy policy URL",
        inputType: "text",
        name: "privacy_url", 
        status: "PROGRAM"  
      },
      privacy_dept:{
        label: "Company/brand privacy policy department",
        inputType: "text",
        name: "privacy_dept", 
        status: "PROGRAM"  
      },
      mailing_address: {
        inputType: "custom_address",
        label: "Privacy policy mailing address",
        name: "mailing_address",
        status: "PROGRAM"  
      },
      privacy_email:{
        label: "Privacy policy contact email address",
        inputType: "text",
        name: "privacy_email", 
        status: "PROGRAM"  
      },
      program_execute: {
        label: "How is the program being executed? (Select all that apply)",
        inputType: "checkbox",
        name: "program_execute",  
        options: [
            {label: "Specific vanity URL", value: "vanity_URL",
            components:[
                {
                    label: "",
                    inputType: "text",
                    placeHolder: "Microsite Unique URL",
                    name: "program_execute.vanity_URL.url", 
                },
                {
                    label: "",
                    inputType: "text",
                    placeHolder: "Browser tab title",
                    name: "program_execute.vanity_URL.title", 
                }
            ]},
            {label: "Within an existing app", value: "existing_app"},
            {label: "On the company/brand website", value: "brand_website",
            components:[
                {
                    label: "",
                    inputType: "text",
                    placeHolder: "Company/brand URL",
                    name: "program_execute.brand_website.url", 
                },               
            ]
        },
            {label: "On company/brand Facebook page", value: "facebook"},
            {label: "On company/brand Twitter page", value: "twitter"},
            // {label: "Other", value: "other", 
            // components:[
            //     {
            //         label: "",
            //         inputType: "text",
            //         placeHolder: "Specify",
            //         name: "program_execute.other.specify", 
            //     },               
            // ]
            // },
        ],
        status: "PROGRAM"  
    },
    microsite_url: {
      label: "Who is responsible for purchasing / providing the Microsite URL of choice?",
      inputType: 'radios',
      name: 'microsite_url',
      options:[
        {label: "WILY Global", value: "wily"},
        {label: "Company/brand", value: "company"},
        {label: "Agency representing the company/brand", value: "agency"},
        {label: "Other", value: "other", 
        components:[
          {
              label: "",
              inputType: "text",
              placeHolder: "Specify",
              name: "other_microsite_url",              
          },               
      ]},
        {label: "N/A", value: "N/A"},
      ],
      status: "PROGRAM"  
    },
    
    
      qa_date: {
        label: "Microsite testing date",
        inputType: "date",
        name: "qa_date",
        status: "PROGRAM"  
      },
      training_date: {
        label: "Event/Staff Training Date, if applicable  ",
        inputType: "date",
        name: "training_date",
        status: "PROGRAM"  
      },
      communicate: {
        label: "How would you like to communicate with your audience?",
        inputType: "checkbox",
        name: "communicate",
        options: [{label: 'Email deployment', value: 'email'},{label: 'SMS', value: 'sms'}] ,
        status: "COMMUNICATE"
    },
    numberofemails: {
        label: "How many emails per campaign?",
        inputType: "text",
        name: "numberofemails",  
        status: "COMMUNICATE"      
    },
    numberofsms: {
        label: "How many text messages per campaign?",
        inputType: "text",
        name: "numberofsms",    
        status: "COMMUNICATE"  
    }, 
    "integration": {
      label: "Do you require your solution to be integrated into a third-party technology?(Website, CRM, App, etc.)",
      inputType: "radios",
      name: "integration",
      options: [{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}], 
      status: "REPORT"
    },
    "integration_detail": {
      label: "Please explain your technology integration needs",
      inputType: "textarea",
      name: "integration_detail", 
      status: "REPORT"
    },
    "report_description": {
      label: "Please describe as best you can your reporting needs for the program",
      inputType: "textarea",
      name: "report_description", 
      status: "REPORT"
    },
    "google_analytics": {
        label: "Would you like Google Analytics reporting to be set-up for this contest/sweepstakes?",
        inputType: "radios",
        name: "integration",
        options: [{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}], 
        status: "REPORT"
      }, 
      legal_service: {
        label: "Do you require legal services?",
        inputType: "radios",
        name: "legal_service",
        options: [{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}, {label: 'Not sure, please quote it anyway', value: 'Not sure, please quote it anyway'}],
        status: "ADDITIONAL"
    },
    rules_responsible: {
      label: "Please indicate who is responsible for developing the Official Rules & Regulations and the Declaration & Release form(s) of the contest/sweepstakes?",
      inputType: "radios",
      name: "rules_responsible", 
      options: [{label: 'WILY Global', value: 'WILY'},{label: 'Company/brand (Sponsor)', value: 'company'}, {label: 'Agency representing the Company/Brand', value: 'agency'}],
      status: "ADDITIONAL" 
  },
  quebec_responsible: {
      label: "Who is filing and registering for this contest with the Quebec Regie?",
      inputType: "radios",
      name: "quebec_responsible", 
      options: [{label: 'WILY Global', value: 'WILY'},{label: 'Company/brand (Sponsor)', value: 'company'}, {label: 'Agency representing the Company/Brand', value: 'agency'}],
      status: "ADDITIONAL" 
  },

    design_service: {
        label: "Do you require creative services for the microsite design?",
        inputType: "radios",
        name: "design_service", 
        options: [{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}, {label: 'Not sure, please quote it anyway', value: 'Not sure, please quote it anyway'}],
        status: "ADDITIONAL" 
    },
    creative_assets: {
      label: "Who is developing the contest/sweepstakes creative assets and providing final creative files? Please Note: Final creative files must be provided in a PSD format, all logos, fonts and any other assets (i.e. product shots) must be provided.",
      inputType: "radios",
      name: "creative_assets", 
      options: [{label: 'WILY Global', value: 'WILY'},{label: 'Company/brand (Sponsor)', value: 'company'}, {label: 'Agency representing the Company/Brand', value: 'agency'}],
      status: "ADDITIONAL" 
  },   
    translate_service: {
        label: "Do you require translation services?",
        inputType: "radios",
        name: "translate_service", 
        options: [{label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'}, {label: 'Not sure, please quote it anyway', value: 'Not sure, please quote it anyway'}],
        status: "ADDITIONAL" 
    },
    translate_copy: {
      label: "Who is responsible for providing language translations for the contest elements(rules & regulations, creative, contest copy, declaration & release form(s))? Please Note: All final translated elements must be provided to WILY no later than 4-5 business day PRIOR to the contest testing date(s).",
      inputType: "radios",
      name: "translate_copy", 
      options: [{label: 'WILY Global', value: 'WILY'},{label: 'Company/brand (Sponsor)', value: 'company'}, {label: 'Agency representing the Company/Brand', value: 'agency'}],
      status: "ADDITIONAL" 
  } 
      
     
     
  }


