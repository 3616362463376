import {useStores} from "../../stores";
import {useNavigate, useParams} from "react-router-dom";
import {Header, NavMenu} from "../../Components";
import React, {useCallback, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {cmsCreateItem, cmsCreateType, cmsGetType, cmsUpdateType} from "../../api";
import {useForm} from "react-hook-form";
import {showToast} from "../../Components/Toast/ToastManager";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {ItemForm} from "./Item";

type FormType = {
    title: string;
    slug: string;
    enabled: boolean;
};


type CreateItemFormType = {
    title: string;
    subtitle: string;
    slug: string;
    enabled: boolean;
};

const CreateItemDialog = ({open, setOpen}:{open:boolean, setOpen: any}) => {
    const {typeId} = useParams<{ typeId: string }>();
    const queryClient = useQueryClient();
    const { userStore } = useStores();
    const {mutate: createItem} = useMutation(
        cmsCreateItem,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been created successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type creation failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: (data) => {
                if (!data?.data) return
                return queryClient.invalidateQueries(`cmsGetType-${typeId}`).then(()=>setOpen(false))
            },
        }
    );

    const submitForm = useCallback((data: CreateItemFormType) => {
        if (!typeId) return
        return createItem({
            type: {
                slug: typeId
            },
            title: data.title,
            subtitle: data.subtitle ?? undefined,
            enabled: data.enabled ?? false,
            slug: data.slug ?? undefined,
            token: userStore.user.token
        })
    }, [userStore.user.token, createItem, typeId])

    return <Modal show={open} onHide={()=>setOpen(false)}>
        <Modal.Header>
            <Modal.Title>New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ItemForm onSubmit={submitForm}/>
        </Modal.Body>
    </Modal>
}

const Items = () => {
    const navigate = useNavigate()
    const { userStore } = useStores();
    const queryClient = useQueryClient();
    const [isCreating, setIsCreating] = useState(false)
    const {typeId} = useParams<{ typeId: string }>();
    const { data: type } = useQuery(`cmsGetType-${typeId}`, async () => {
        return cmsGetType({token: userStore.user.token, slug: typeId || ''});
    });
    const { mutate: updateType } = useMutation(
        cmsUpdateType,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(`cmsGetType-${typeId}`);
            },
        }
    );

    const { mutate: createType } = useMutation(
        cmsCreateType,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been created successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type creation failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: (data) => {
                if (!data?.data) return
                return queryClient.invalidateQueries(`cmsListTypes`).then(()=> navigate(`/cms/type/${data?.data.slug}`));
            },
        }
    );
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<FormType>({
        defaultValues: {
            title: "",
            slug: "",
            enabled: false,
        },
        mode: "onTouched",
    });

    useEffect(() => {
        if (type?.slug) {
            setValue('slug', type.slug)
            setValue('title', type.title)
            setValue('enabled', type.enabled)
        }
    },[setValue, type])

    const submitForm = (data: FormType) => {
        if (!typeId) return
        if (typeId === '*') return createType({token: userStore.user.token, title: data.title, enabled: data.enabled, slug: data.slug})
        return updateType({token: userStore.user.token, enabled: data.enabled, slug: data.slug, oldSlug: typeId, title: data.title})
    }

    if (!typeId) return <div/>

    const editing = (typeId !== '*' && !!type)
    const creating = (typeId === '*' && !type)
    const isValid = editing || creating
    return <>
        <div className="container-fluid p-0">
            <CreateItemDialog open={isCreating} setOpen={setIsCreating}/>
            <div className="row no-gutters">
                <NavMenu />
                <div className="col-sm-12 col-md-10">
                    <Header />
                    <div className="dashboardContent">
                        <div className="head">
                            <div className={'flex justify-content-between'}>
                                <Link to={'/cms'}><i className="fas fa-arrow-left"></i>Back to types</Link>
                                <Link onClick={()=>setIsCreating(true)} to={`#`}>Create Item</Link>
                            </div>
                            {isValid ? <div className={'container row row-cols-2'}>
                                <form className={'col-6'} onSubmit={handleSubmit(submitForm)}>
                                    <div className="mt-2">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="">
                                                        Slug
                                                    </label>
                                                    <input
                                                        {...register("slug", {
                                                            required: {
                                                                value: true,
                                                                message: "Please Enter a type slug",
                                                            },
                                                        })}
                                                        placeholder="Slug (unique)"
                                                        className="form-control"
                                                    />

                                                    {errors.slug && (
                                                        <div className="error">
                                                            <i className="fas fa-exclamation-circle" />
                                                            {errors.slug.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={'text-muted'}>Used by the API to identify different types, required and unique.</div>
                                                <div className="italic-p">Example: inspiration-tag</div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-6 col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="">
                                                        Display Name
                                                    </label>
                                                    <input
                                                        {...register("title")}
                                                        placeholder="Title"
                                                        className="form-control"
                                                    />

                                                    {errors.title && (
                                                        <div className="error">
                                                            <i className="fas fa-exclamation-circle" />
                                                            {errors.title.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={'text-muted'}>Used only for the admin panel, this is a user-friendly title for this CMS type</div>
                                                <div className="italic-p">Example: Inspiration Tag</div>
                                            </div>
                                        </div>

                                        <div className="form-group toggleBtn">
                                            <p>Enabled</p>
                                            <div onClick={()=>setValue('enabled', !getValues('enabled'))} className="swtichCont">
                                                <label className="switch" htmlFor="checkbox1">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        {...register("enabled", { required: false })}
                                                    />

                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <button className="btn form" type="submit">
                                                        {editing ? 'Save' : 'Create'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className={''}>
                                    <p className={'ml-1 mb-2'}><span
                                        className={'font-weight-bold'}>Items:</span> {editing ? type?.items?.length : 0}
                                    </p>
                                    <div style={{maxHeight: 400, overflow: 'auto'}}>
                                        {type?.items?.map((item)=>{
                                            return <div className={'border flex p-2 rounded mb-2 justify-content-between'}>
                                                <Link to={`/cms/item/${item.id}`}>{item.title ?? item.slug}</Link>
                                                <span className={'font-weight-bold small text-muted'}>{!item.enabled ? 'DISABLED' : ''}</span>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Items