import {CellProps, Column} from "react-table";
import {ICMSItem} from "../../types/portalTypes";
import {useMutation, useQueryClient} from "react-query";
import {cmsUpdateType} from "../../api";
import {showToast} from "../../Components/Toast/ToastManager";
import {useStores} from "../../stores";
import {Link} from "react-router-dom";

// TODO: Extract the switch component here to the ui kit
const RenderEnableSwitch = (props: CellProps<ICMSItem, string>) => {
    const queryClient = useQueryClient();
    const parentLoading = queryClient.getQueryState('cmsListTypes')
    const { userStore } = useStores();
    const { mutate: updateType, isLoading } = useMutation(
        cmsUpdateType,
        {
            onSuccess: (data) => {
                showToast({
                    content: "Type has been updated successfully.",
                    duration: 3000,
                    error: false,
                });
            },
            onError: () => {
                showToast({
                    content: "Type update failed",
                    duration: 3000,
                    error: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries("cmsListTypes");
            },
        }
    );

    const handleChange = (event: any) => {
        // console.log(event)
        event.preventDefault();
        return updateType({oldSlug: props.row.original.slug, token: userStore.user.token, enabled: !props.row.original.enabled})
    }

    const disabled = isLoading || parentLoading?.isFetching
    return <div onClick={disabled ? undefined : handleChange} className="swtichCont">
        <label className="switch" htmlFor="checkbox">
            <input
                disabled={disabled}
                checked={props.row.original.enabled}
                onChange={() => {}}
                type="checkbox"
                className="form-check-input"
            />
            <div className="slider round"></div>
        </label>
    </div>
}

export const TypesColumns: ()=> Column<ICMSItem>[] = () => {
    return [
        {
            width: '90%',
            Header: "Content Type",
            accessor: "slug",
            Cell: (props, context) => {
                return <Link to={`/cms/type/${props.row.original.slug}`}>{props.row.original.title ?? props.row.original.slug}</Link>
            }
        },
        {
            width: '10%',
            Header: "Enabled",
            Cell: RenderEnableSwitch
        },
    ];
};