import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getPrograms, spryClient } from "../../api";
import { Header, NavMenu, Table } from "../../Components";
import { useStores } from "../../stores";
import {EProgramStatus} from "../../utils/constants/quotes";
import { ProgramColumns } from "./ProgramCols";
import { Spinner } from "react-bootstrap";
import { TProgram } from "../../types/portalTypes";

const Programs = () => {
  const { userStore } = useStores();


  const { data: programs, isLoading: programsLoading } = useQuery("listPrograms", async () => {
    const res:TProgram[] = await getPrograms({ token: userStore.user.token });
    return res;
  });


  const { isLoading: loadingBrands} = useQuery(
    "getBrands",
    () => spryClient.getBrands({})
  );

  const data = useMemo(() => {
    if (programs && programs.length) {
      
      return programs.map((p) => {
        return {
          id: p.id,
          status: EProgramStatus[p.status??0],
          creationTime: new Date(p.created_at),
          updateTime: new Date(p.updated_at),
          company: p.company?.name||"N/A",
          programBrand: p.quotes.length?p.quotes[0].program_brand?.id:"N/A", // need it to be get from api
          programName: p.quotes.length?p.quotes[0].program_name:"N/A",// need it to be get from api
          brandId: p?.quotes.length?p.quotes[0].program_brand?.id:"N/A",// need it to be get from api
        };
      });
    } else {
      return [];
    }
  }, [programs]);


  const columns = ProgramColumns();

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <NavMenu />
          <div className="col-sm-12 col-md-10">
            <Header />

            <div className="dashboardContent quotes">
              <div className="head">
                
              {  programsLoading || loadingBrands? (
                <div className="spinner">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={data}
                  tablePageSize={15}
                  tableTitle="Quotes"
                  sortbyid="updateTime"
                  descending={true}
                
                />
              )} 
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Programs;
