import React from "react";
import { useForm } from "react-hook-form";
import { SelectOption, TProgram, TQuote } from "../../types/portalTypes";
import { overviewFormFields } from "./questions";
import icoCal from "../../assets/images/ico-calendar.png";
import icoClock from "../../assets/images/ico-clock.png";

const Details = ({ details, quote }: { details: TProgram; quote: TQuote }) => {
  const updatedQuestions: { [key: string]: any } = overviewFormFields;
  const answers = [...details.answers, ...quote.answers];
  const features = details.features?.length ? details.features : quote.features;

  Object.keys(updatedQuestions).map((a) => {
    const datelabels = ["start_date", "end_date", "qa_date", "training_date"];

    if (datelabels.includes(a.toLowerCase())) {
      let ans = answers.find((ans) => ans.questionLabel === a)?.value;
      if (ans) {
        ans = new Date(ans.slice(1, -1)).toLocaleString();
      } else {
        ans = "N/A";
      }
      updatedQuestions[a].answer = ans;
    } else {
      let ans = answers.find((ans) => ans.questionLabel === a)?.value ?? "N/A";

      if (updatedQuestions[a].inputType === "radios" || updatedQuestions[a].inputType === "checkbox") {
        ans = updatedQuestions[a].options.find((o: SelectOption) => o.value === ans)?.label;
      }
      if (a === "mailing_address" && ans !== "N/A") {
        console.log(ans);
        ans = Object.values(JSON.parse(ans))?.join(",");
      }
      updatedQuestions[a].answer = ans;
    }
    return a;
  });

  const programDetailsForm = useForm<TProgram>({
    defaultValues: {
      ...details,
      program_name: details.quotes[0].program_name,
      current_address: details.quotes[0].current_address,
      program_description: details.quotes[0].program_description,
    },
  });
  const submitform = () => {};

  return (
    <>
      {details && (
        <div>
          <h2>
            <strong>Program Overview</strong>
          </h2>
          <form onSubmit={programDetailsForm.handleSubmit(submitform)}>
            <div id="tab05" className="tab-contents program-details">
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="form-group">
                    <label htmlFor="program_name">Program name</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("program_name")}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="form-group">
                    <label htmlFor="company.name">Company/Brand</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("company.name")}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <p style={{ fontWeight: "600" }}>Company/Brand mailing address</p>
                  <div className="form-group">
                    <label htmlFor="current_address.country">Country/region</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.country")}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="form-group">
                    <label htmlFor="current_address.address1">Address 1</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.line1")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-3">
                  <div className="form-group">
                    <label htmlFor="current_address.address2">Address 2</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.line2")}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <div className="form-group">
                    <label htmlFor="current_address.city">City</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.city")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-2">
                  <div className="form-group">
                    <label htmlFor="current_address.province">Province/State</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.state")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-2">
                  <div className="form-group">
                    <label htmlFor="current_address.postal_code">Postal/Zip code</label>
                    <input
                      className="form-control"
                      {...programDetailsForm.register("current_address.postal_code")}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="form-group">
                    <label htmlFor="program_description">
                      Please give a breif description of the program mechanics
                    </label>
                    <textarea
                      className="form-control"
                      {...programDetailsForm.register("program_description")}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              {Object.keys(updatedQuestions).map(
                (a, i) =>
                  updatedQuestions[a].status === "QUOTE" && (
                    <div className="form-group" key={i}>
                      {updatedQuestions[a].inputType === "date" && (
                        <div className="row">
                          <div className="col-sm-6 col-md-4">
                            <div className="clock start">
                              <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                              <input
                                className="form-control"
                                value={new Date(updatedQuestions[a].answer).toLocaleDateString()}
                                readOnly
                              />
                              <span className="timeIco" style={{ padding: "8px" }}>
                                <img src={icoCal} alt="Select Date" />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {updatedQuestions[a].inputType === "time" && (
                        <div className="row">
                          <div className="col-sm-6 col-md-4">
                            <div className="clock start">
                              <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                              <input
                                className="form-control"
                                value={new Date(updatedQuestions[a].answer).toLocaleDateString()}
                                readOnly
                              />
                              <span className="timeIco" style={{ padding: "8px" }}>
                                <img src={icoClock} alt="Select Date" />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {(updatedQuestions[a].inputType === "text" ||
                        updatedQuestions[a].inputType === "textarea") && (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                            <input
                              className="form-control"
                              value={updatedQuestions[a].answer}
                              type={updatedQuestions[a].inputType}
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      {updatedQuestions[a].inputType === "radios" && (
                        <div>
                          <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>
                          <li style={{ fontSize: "14px" }}> {updatedQuestions[a].answer}</li>
                        </div>
                      )}
                    </div>
                  )
              )}
              <h2>
        <strong>Program Configuration</strong>
      </h2>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form-group">
            <label style={{ width: "100%" }}>Program modules</label>
            {features.map((f) => (
              <li style={{fontSize:"14px"}}>{f.name}</li>
            ))}
             <div className="form-group">
            <strong style={{ width: "100%" }}>Other details</strong>
            <textarea value="yet to get..." className="form-control" readOnly></textarea>
            </div>
          </div>
        </div>
      </div>
      <h2>
        <strong>Communication</strong>
      </h2>
      {Object.keys(updatedQuestions).map(
                (a, i) =>
                  updatedQuestions[a].status === "COMMUNICATE" && (
                    <div className="form-group" key={i}>
                      
                      {(updatedQuestions[a].inputType === "text" ||
                        updatedQuestions[a].inputType === "textarea") && (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                            <input
                              className="form-control"
                              value={updatedQuestions[a].answer||"N/A"}
                              type={updatedQuestions[a].inputType}
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      {updatedQuestions[a].inputType === "checkbox" && (
                        <div>
                          <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>
                          <li style={{ fontSize: "14px" }}> {updatedQuestions[a].answer}</li>
                        </div>
                      )}
                    </div>
                  )
              )}
       <h2>
        <strong>Data & Reporting</strong>
      </h2>
      {Object.keys(updatedQuestions).map(
                (a, i) =>
                  updatedQuestions[a].status === "REPORT" && (
                    <div className="form-group" key={i}>
                      
                      {(updatedQuestions[a].inputType === "text" ||
                        updatedQuestions[a].inputType === "textarea") && (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                            <input
                              className="form-control"
                              value={updatedQuestions[a].answer||"N/A"}
                              type={updatedQuestions[a].inputType}
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      {(updatedQuestions[a].inputType === "checkbox"||updatedQuestions[a].inputType === "radios") && (
                        <div>
                          <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>
                          <li style={{ fontSize: "14px" }}> {updatedQuestions[a].answer||"N/A"}</li>
                        </div>
                      )}
                    </div>
                  )
              )}
       <h2>
        <strong>Additional services</strong>
      </h2>
      {Object.keys(updatedQuestions).map(
                (a, i) =>
                  updatedQuestions[a].status === "ADDITIONAL" && (
                    <div className="form-group" key={i}>
                      
                      {(updatedQuestions[a].inputType === "text" ||
                        updatedQuestions[a].inputType === "textarea") && (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                            <input
                              className="form-control"
                              value={updatedQuestions[a].answer||"N/A"}
                              type={updatedQuestions[a].inputType}
                              readOnly
                            />
                          </div>
                        </div>
                      )}

                      {(updatedQuestions[a].inputType === "checkbox"||updatedQuestions[a].inputType === "radios") && (
                        <div>
                          <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>
                          <li style={{ fontSize: "14px" }}> {updatedQuestions[a].answer||"N/A"}</li>
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>

          </form>
        </div>
      )}


    

      {/* {Object.keys(updatedQuestions).map((a, i) => (
                updatedQuestions[a].status ==="PROGRAM" &&
                <div className="form-group" key={i}>
                  {updatedQuestions[a].inputType === "date" && (
                                  <div className="row">

                    <div className="col-sm-6 col-md-4">
                      <div className="clock start">
                        <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                        <input
                          className="form-control"
                          value={updatedQuestions[a].answer==="N/A" ? "":new Date(updatedQuestions[a].answer).toLocaleDateString()}
                          readOnly
                        />
                        <span className="timeIco" style={{ padding: "8px" }}>
                          <img src={icoCal} alt="Select Date" />
                        </span>
                      </div>
                    </div>
                    </div>
                  )}
                  {updatedQuestions[a].inputType === "time" && (
                                  <div className="row">

                    <div className="col-sm-6 col-md-4">
                      <div className="clock start">
                        <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                        <input
                          className="form-control"
                          value={updatedQuestions[a].answer==="N/A" ? "":new Date(updatedQuestions[a].answer).toLocaleDateString()}
                          readOnly
                        />
                        <span className="timeIco" style={{ padding: "8px" }}>
                          <img src={icoClock} alt="Select Date" />
                        </span>
                      </div>
                    </div>
                    </div>
                  )}
                  {(updatedQuestions[a].inputType === "text" || updatedQuestions[a].inputType === "textarea") && (
                                  <div className="row">

                    <div className="col-sm-12 col-md-6">
                        <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>

                        <input
                          className="form-control"
                          value={updatedQuestions[a].answer}
                          type={updatedQuestions[a].inputType}
                          readOnly
                        />
                       
                    </div>
                    </div>
                  )}

                  {updatedQuestions[a].inputType === "radios" &&
                   (
                      <div>
                        <label style={{ width: "100%" }}>{updatedQuestions[a].label}</label>
                        <li style={{fontSize:"14px"}}> {updatedQuestions[a].answer}</li>
                      </div>
                    )}
                </div>
              ))} */}
    </>
  );
};

export default Details;
