import { useQuery, useQueryClient } from "react-query"
import { spryClient } from "../../../api"
import { Table } from "../../../Components"
import { Spinner } from "react-bootstrap"
import { useState } from "react"
import { generateKey } from "@sprycore/spry-api-client"
import { Keyword } from "@sprycore/spry-api-client/dist/ReturnTypes"
import { useNavigate } from "react-router-dom"

type CampaignKeywordsProps = {
    campaignKey: string
}
const DefaultDate = new Date(1990, 0, 1)
export default function CampaignKeywords({ campaignKey }: CampaignKeywordsProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [newWord, setNewWord] = useState("")
    const [addError, setAddError] = useState("")
    const [adding, setAdding] = useState(false)

    const keywordsQuery = useQuery(
        ["getKeywords", campaignKey],
        async () => {
            const { keywords } = await spryClient.getKeywords({ campaignKey })
            return keywords
        })

    async function addKeyword() {
        if (!newWord || adding) { return }
        setAddError("")
        setAdding(true)
        try {
            const keywordKey = generateKey()
            await spryClient.createKeyword({ campaignKey, startTime: DefaultDate, endTime: DefaultDate, word: newWord, keywordKey })
            setNewWord("")
        }
        catch {
            setAddError("Error adding new keyword")
        }
        finally {
            setAdding(false)
            queryClient.invalidateQueries("getKeywords")
        }
    }

    const loading = keywordsQuery.isLoading

    const columns = [
        {
            Header: "Word",
            accessor:"word",
            Cell: (table: any) => {
                const row = table.row.original as Keyword
                return <div>{row.word}</div>
            }
        },
        {
            Header: "Start",
            Cell: (table: any) => {
                const row = table.row.original as Keyword
                return <div>{row.startTime.toISOString()}</div>
            }
        },
        {
            Header: "End",
            Cell: (table: any) => {
                const row = table.row.original as Keyword
                return <div>{row.endTime.toISOString()}</div>
            }
        },
        {
            Header: "Options",
            Cell: (table: any) => {
                const row = table.row.original as Keyword
                return <div className="keywordRow"><button className="btn btn-sm outline" onClick={() => navigate(`/campaign/${row.campaignKey}/keywords/${row.keywordKey}`)}>Edit</button></div>
            }
        }
    ]

    console.log(keywordsQuery.data)

    return <>
        <div className="dashboardContent campaignDetail tabsCont keywords">
            
            <div className="tab-contents">
                {loading && <div className="spinner"><Spinner animation="border" variant="secondary" /></div>}
                {!loading && <>
                    <Table columns={columns} data={keywordsQuery.data} tablePageSize={15} sortbyid="no" tableTitle="Keywords" />
                </>}
            </div>
            {!loading && <>
                <div className="tab-contents">
                <form className="form-inline">
                <div className="form-group mb-2">
                <input className="form-control form-control-lg" maxLength={50} value={newWord} onChange={e => setNewWord(e.target.value.toUpperCase().replaceAll(/[^A-Z0-9]/g, ""))} placeholder="New Keyword" />
                </div>
                <button className="btn btn-sm ml-2 addKeyword" type="button" onClick={addKeyword} disabled={!newWord || adding}>Add New Keyword</button>
                <span>{ addError }</span>
                </form>
                </div>
            </>}
        </div>
    </>
}